import React, { CSSProperties, FunctionComponent, SVGProps } from 'react';

import { colours } from '../style/variables';

type LogoKind = 'wordmark' | 'full';

export interface LogoProps {
  kind: LogoKind;
  /**
   * Height (in pixels) of logo. Inferred from width if excluded
   */
  height?: number;
  /**
   * Width (in pixels) of logo. Inferred from height if excluded
   */
  width?: number;
  title?: string;
  fill?: string;
  style?: CSSProperties;
  className?: string;
}

const ratios: Record<LogoKind, number> = {
  wordmark: 600 / 138,
  full: 600 / 210,
};

const DEFAULT_HEIGHT = 50;

export const Logo: FunctionComponent<LogoProps> = ({
  kind,
  height,
  width,
  // title,
  fill = colours.black,
  style,
  className
}) => {
  const Element = kind === 'wordmark' ? WordmarkLogo : FullLogo;

  return (
    <Element
      // title={title}
      fill={fill}
      height={
        height
          ? `${height}px`
          : width
          ? `${width / ratios[kind]}px`
          : `${DEFAULT_HEIGHT}px`
      }
      width={
        width
          ? `${width}px`
          : height
          ? `${height * ratios[kind]}px`
          : `${DEFAULT_HEIGHT * ratios[kind]}px`
      }
      className={className}
      style={style}
    />
  );
};

const WordmarkLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 600 138" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M400.535 58.8767V108.546H363.682V66.5181C363.682 53.2603 357.953 46.345 347.145 46.345C335.116 46.345 328.929 53.4132 328.929 67.1295V108.508H292.228V20.6318H328.623V42.6008C334.81 24.9874 345.923 16.6964 364.293 16.6964C388.047 16.6964 400.535 31.1386 400.535 58.724" />
    <path d="M561.046 20.6699H600L541.035 137.316H499.484L519.19 100.752L480.695 20.6699H519.954L539.813 68.0847L561.046 20.6699Z" />
    <path d="M74.5083 16.7346C54.3441 16.7346 41.7034 25.7896 36.7387 42.1803V20.6698H0V137.316H36.7387V90.9323C43.4983 105.107 56.139 111.717 73.439 111.717C98.2624 111.717 115.715 92.6135 115.715 63.9965C115.715 37.2517 100.057 16.7346 74.5083 16.7346ZM68.9327 80.6547C50.3724 83.9405 37.8843 73.5866 37.2351 67.206C36.1657 56.9284 43.0781 48.8283 53.6185 52.7254C56.2074 53.7369 58.9703 54.2272 61.749 54.1682C64.5276 54.1092 67.2673 53.5022 69.811 52.3817C77.4489 50.0511 83.1393 49.8218 87.4165 57.3868C92.2666 65.9834 85.3161 77.7509 68.9327 80.6547Z" />
    <path d="M122.78 5.73103V108.699H159.481V0.343862L122.78 5.73103Z" />
    <path d="M463.739 20.6698V0L426.886 5.42531V20.7081H394.501L395.761 22.0837C401.558 29.2226 405.166 37.8875 406.149 47.0327H426.886V108.737H463.739V46.9944H495.933V20.6698H463.739Z" />
    <path d="M245.866 20.6318H282.719V108.737H245.866V89.175C239.832 104.228 227.459 111.755 209.548 111.755C183.502 111.755 166.813 92.0404 166.813 64.455C166.813 36.8696 183.808 16.6964 210.311 16.6964C228.528 16.6964 239.832 24.3378 245.866 39.6205V20.6318ZM245.866 63.8438C245.866 52.6874 238.228 44.7403 225.396 44.7403C212.564 44.7403 204.926 52.3817 204.926 63.8438C204.926 75.3058 212.908 83.2909 225.243 83.2909C237.579 83.2909 245.866 75.0001 245.866 63.5381" />
  </svg>
);

const FullLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 600 210" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M400.535 58.8767V108.546H363.682V66.5181C363.682 53.2603 357.953 46.345 347.145 46.345C335.116 46.345 328.929 53.4132 328.929 67.1295V108.508H292.228V20.6318H328.623V42.6008C334.81 24.9874 345.923 16.6964 364.293 16.6964C388.047 16.6964 400.535 31.1386 400.535 58.724" />
    <path d="M561.046 20.6699H600L541.035 137.316H499.484L519.19 100.752L480.695 20.6699H519.954L539.813 68.0847L561.046 20.6699Z" />
    <path d="M74.5083 16.7346C54.3441 16.7346 41.7034 25.7896 36.7387 42.1803V20.6698H0V137.316H36.7387V90.9323C43.4983 105.107 56.139 111.717 73.439 111.717C98.2624 111.717 115.715 92.6135 115.715 63.9965C115.715 37.2517 100.057 16.7346 74.5083 16.7346ZM68.9327 80.6547C50.3724 83.9405 37.8843 73.5866 37.2351 67.206C36.1657 56.9284 43.0781 48.8283 53.6185 52.7254C56.2074 53.7369 58.9703 54.2272 61.749 54.1682C64.5276 54.1092 67.2673 53.5022 69.811 52.3817C77.4489 50.0511 83.1393 49.8218 87.4165 57.3868C92.2666 65.9834 85.3161 77.7509 68.9327 80.6547Z" />
    <path d="M122.78 5.73103V108.699H159.481V0.343862L122.78 5.73103Z" />
    <path d="M463.739 20.6698V0L426.886 5.42531V20.7081H394.501L395.761 22.0837C401.558 29.2226 405.166 37.8875 406.149 47.0327H426.886V108.737H463.739V46.9944H495.933V20.6698H463.739Z" />
    <path d="M245.866 20.6318H282.719V108.737H245.866V89.175C239.832 104.228 227.459 111.755 209.548 111.755C183.502 111.755 166.813 92.0404 166.813 64.455C166.813 36.8696 183.808 16.6964 210.311 16.6964C228.528 16.6964 239.832 24.3378 245.866 39.6205V20.6318ZM245.866 63.8438C245.866 52.6874 238.228 44.7403 225.396 44.7403C212.564 44.7403 204.926 52.3817 204.926 63.8438C204.926 75.3058 212.908 83.2909 225.243 83.2909C237.579 83.2909 245.866 75.0001 245.866 63.5381" />
    <path d="M46.3355 192.03L62.2548 157.207H65.8864L81.8058 192.03H77.9254L63.2995 159.346H64.792L50.1661 192.03H46.3355ZM52.6037 182.727L53.6982 179.742H73.9456L75.0401 182.727H52.6037Z" />
    <path d="M113.322 192.279C111.034 192.279 108.977 191.765 107.153 190.737C105.329 189.675 103.886 188.15 102.825 186.16C101.764 184.17 101.233 181.765 101.233 178.946C101.233 176.094 101.764 173.69 102.825 171.733C103.92 169.743 105.379 168.234 107.203 167.206C109.027 166.178 111.067 165.664 113.322 165.664C115.809 165.664 118.031 166.228 119.988 167.355C121.978 168.45 123.537 169.992 124.664 171.982C125.792 173.972 126.356 176.293 126.356 178.946C126.356 181.566 125.792 183.888 124.664 185.911C123.537 187.901 121.978 189.46 119.988 190.587C118.031 191.715 115.809 192.279 113.322 192.279ZM99.8402 192.03V155.117H103.372V173.623L102.875 178.897L103.223 184.17V192.03H99.8402ZM113.073 189.145C114.93 189.145 116.589 188.73 118.048 187.901C119.507 187.039 120.668 185.845 121.53 184.319C122.393 182.76 122.824 180.97 122.824 178.946C122.824 176.89 122.393 175.099 121.53 173.574C120.668 172.048 119.507 170.871 118.048 170.042C116.589 169.179 114.93 168.748 113.073 168.748C111.216 168.748 109.541 169.179 108.049 170.042C106.589 170.871 105.429 172.048 104.566 173.574C103.737 175.099 103.323 176.89 103.323 178.946C103.323 180.97 103.737 182.76 104.566 184.319C105.429 185.845 106.589 187.039 108.049 187.901C109.541 188.73 111.216 189.145 113.073 189.145Z" />
    <path d="M144.65 192.279C141.93 192.279 139.542 191.715 137.486 190.587C135.43 189.427 133.821 187.851 132.661 185.861C131.5 183.838 130.919 181.533 130.919 178.946C130.919 176.36 131.467 174.071 132.561 172.081C133.689 170.091 135.214 168.533 137.138 167.405C139.095 166.244 141.284 165.664 143.705 165.664C146.159 165.664 148.331 166.228 150.222 167.355C152.145 168.45 153.654 170.008 154.749 172.031C155.843 174.021 156.39 176.326 156.39 178.946C156.39 179.112 156.374 179.295 156.341 179.494C156.341 179.659 156.341 179.842 156.341 180.041H133.606V177.404H154.45L153.057 178.449C153.057 176.559 152.643 174.884 151.814 173.424C151.018 171.932 149.923 170.771 148.53 169.942C147.137 169.113 145.529 168.698 143.705 168.698C141.914 168.698 140.305 169.113 138.879 169.942C137.453 170.771 136.342 171.932 135.546 173.424C134.75 174.917 134.352 176.625 134.352 178.548V179.096C134.352 181.086 134.783 182.843 135.646 184.369C136.541 185.861 137.768 187.039 139.327 187.901C140.919 188.73 142.726 189.145 144.749 189.145C146.341 189.145 147.817 188.863 149.177 188.299C150.57 187.735 151.764 186.873 152.759 185.712L154.749 188.001C153.588 189.394 152.129 190.455 150.371 191.184C148.646 191.914 146.739 192.279 144.65 192.279Z" />
    <path d="M171.838 192.279C169.384 192.279 167.493 191.616 166.167 190.289C164.84 188.962 164.177 187.089 164.177 184.667V160.142H167.709V184.468C167.709 185.994 168.09 187.171 168.853 188.001C169.649 188.83 170.777 189.244 172.236 189.244C173.795 189.244 175.088 188.797 176.116 187.901L177.36 190.438C176.664 191.068 175.818 191.533 174.823 191.831C173.861 192.13 172.866 192.279 171.838 192.279ZM159.501 168.848V165.912H175.669V168.848H159.501Z" />
    <path d="M191.368 192.279C188.914 192.279 187.023 191.616 185.697 190.289C184.37 188.962 183.707 187.089 183.707 184.667V160.142H187.239V184.468C187.239 185.994 187.62 187.171 188.383 188.001C189.179 188.83 190.307 189.244 191.766 189.244C193.325 189.244 194.618 188.797 195.646 187.901L196.89 190.438C196.194 191.068 195.348 191.533 194.353 191.831C193.391 192.13 192.396 192.279 191.368 192.279ZM179.03 168.848V165.912H195.199V168.848H179.03Z" />
    <path d="M213.491 192.279C210.771 192.279 208.383 191.715 206.327 190.587C204.271 189.427 202.662 187.851 201.501 185.861C200.341 183.838 199.76 181.533 199.76 178.946C199.76 176.36 200.307 174.071 201.402 172.081C202.53 170.091 204.055 168.533 205.979 167.405C207.935 166.244 210.124 165.664 212.545 165.664C215 165.664 217.172 166.228 219.062 167.355C220.986 168.45 222.495 170.008 223.59 172.031C224.684 174.021 225.231 176.326 225.231 178.946C225.231 179.112 225.215 179.295 225.181 179.494C225.181 179.659 225.181 179.842 225.181 180.041H202.447V177.404H223.291L221.898 178.449C221.898 176.559 221.484 174.884 220.654 173.424C219.858 171.932 218.764 170.771 217.371 169.942C215.978 169.113 214.37 168.698 212.545 168.698C210.755 168.698 209.146 169.113 207.72 169.942C206.294 170.771 205.183 171.932 204.387 173.424C203.591 174.917 203.193 176.625 203.193 178.548V179.096C203.193 181.086 203.624 182.843 204.486 184.369C205.382 185.861 206.609 187.039 208.168 187.901C209.76 188.73 211.567 189.145 213.59 189.145C215.182 189.145 216.658 188.863 218.018 188.299C219.411 187.735 220.605 186.873 221.6 185.712L223.59 188.001C222.429 189.394 220.969 190.455 219.212 191.184C217.487 191.914 215.58 192.279 213.491 192.279Z" />
    <path d="M232.421 192.03V165.912H235.803V173.026L235.455 171.783C236.185 169.793 237.412 168.284 239.137 167.256C240.861 166.194 243 165.664 245.554 165.664V169.096C245.421 169.096 245.289 169.096 245.156 169.096C245.023 169.063 244.891 169.047 244.758 169.047C242.005 169.047 239.85 169.892 238.291 171.584C236.732 173.242 235.953 175.613 235.953 178.698V192.03H232.421Z" />
    <path d="M270.731 192.03L260.881 165.912H264.264L273.218 190.14H271.676L280.879 165.912H283.914L293.018 190.14H291.476L300.58 165.912H303.813L293.913 192.03H290.58L281.825 169.246H282.869L274.064 192.03H270.731Z" />
    <path d="M324.721 192.03V186.259L324.572 185.314V175.663C324.572 173.441 323.942 171.733 322.682 170.539C321.455 169.345 319.614 168.748 317.16 168.748C315.468 168.748 313.86 169.03 312.334 169.594C310.809 170.158 309.515 170.904 308.454 171.832L306.862 169.196C308.188 168.068 309.78 167.206 311.638 166.609C313.495 165.979 315.452 165.664 317.508 165.664C320.891 165.664 323.494 166.509 325.318 168.201C327.176 169.859 328.104 172.396 328.104 175.812V192.03H324.721ZM315.717 192.279C313.76 192.279 312.052 191.964 310.593 191.334C309.167 190.67 308.072 189.775 307.31 188.647C306.547 187.487 306.165 186.16 306.165 184.667C306.165 183.308 306.48 182.081 307.111 180.986C307.774 179.858 308.835 178.963 310.294 178.3C311.787 177.603 313.777 177.255 316.264 177.255H325.269V179.892H316.364C313.843 179.892 312.085 180.339 311.09 181.235C310.129 182.13 309.648 183.241 309.648 184.568C309.648 186.06 310.228 187.254 311.389 188.15C312.55 189.045 314.175 189.493 316.264 189.493C318.254 189.493 319.962 189.045 321.388 188.15C322.848 187.221 323.909 185.895 324.572 184.17L325.368 186.608C324.705 188.332 323.544 189.709 321.886 190.737C320.261 191.765 318.204 192.279 315.717 192.279Z" />
    <path d="M337.411 201.93C336.184 201.93 335.007 201.731 333.879 201.333C332.784 200.935 331.839 200.338 331.043 199.542L332.685 196.905C333.348 197.536 334.061 198.016 334.824 198.348C335.62 198.713 336.499 198.895 337.461 198.895C338.622 198.895 339.617 198.564 340.446 197.9C341.308 197.27 342.12 196.143 342.883 194.518L344.575 190.687L344.973 190.14L355.718 165.912H359.201L346.117 195.164C345.387 196.856 344.575 198.199 343.679 199.194C342.817 200.189 341.872 200.885 340.844 201.283C339.815 201.714 338.671 201.93 337.411 201.93ZM344.376 192.776L332.337 165.912H336.018L346.664 189.891L344.376 192.776Z" />
    <path d="M385.453 192.279C382.999 192.279 381.109 191.616 379.782 190.289C378.455 188.962 377.792 187.089 377.792 184.667V160.142H381.324V184.468C381.324 185.994 381.705 187.171 382.468 188.001C383.264 188.83 384.392 189.244 385.851 189.244C387.41 189.244 388.703 188.797 389.732 187.901L390.975 190.438C390.279 191.068 389.433 191.533 388.438 191.831C387.476 192.13 386.481 192.279 385.453 192.279ZM373.116 168.848V165.912H389.284V168.848H373.116Z" />
    <path d="M407.128 192.279C404.608 192.279 402.336 191.715 400.313 190.587C398.323 189.427 396.747 187.851 395.587 185.861C394.426 183.838 393.845 181.533 393.845 178.946C393.845 176.326 394.426 174.021 395.587 172.031C396.747 170.042 398.323 168.483 400.313 167.355C402.303 166.228 404.574 165.664 407.128 165.664C409.715 165.664 412.003 166.228 413.993 167.355C416.016 168.483 417.592 170.042 418.719 172.031C419.88 174.021 420.461 176.326 420.461 178.946C420.461 181.533 419.88 183.838 418.719 185.861C417.592 187.851 416.016 189.427 413.993 190.587C411.97 191.715 409.682 192.279 407.128 192.279ZM407.128 189.145C409.019 189.145 410.693 188.73 412.153 187.901C413.612 187.039 414.756 185.845 415.585 184.319C416.448 182.76 416.879 180.97 416.879 178.946C416.879 176.89 416.448 175.099 415.585 173.574C414.756 172.048 413.612 170.871 412.153 170.042C410.693 169.179 409.035 168.748 407.178 168.748C405.321 168.748 403.662 169.179 402.203 170.042C400.744 170.871 399.583 172.048 398.721 173.574C397.858 175.099 397.427 176.89 397.427 178.946C397.427 180.97 397.858 182.76 398.721 184.319C399.583 185.845 400.744 187.039 402.203 187.901C403.662 188.73 405.304 189.145 407.128 189.145Z" />
    <path d="M451.786 192.279C449.066 192.279 446.678 191.715 444.622 190.587C442.566 189.427 440.957 187.851 439.796 185.861C438.635 183.838 438.055 181.533 438.055 178.946C438.055 176.36 438.602 174.071 439.697 172.081C440.824 170.091 442.35 168.533 444.274 167.405C446.23 166.244 448.419 165.664 450.84 165.664C453.295 165.664 455.467 166.228 457.357 167.355C459.281 168.45 460.79 170.008 461.884 172.031C462.979 174.021 463.526 176.326 463.526 178.946C463.526 179.112 463.509 179.295 463.476 179.494C463.476 179.659 463.476 179.842 463.476 180.041H440.741V177.404H461.586L460.193 178.449C460.193 176.559 459.778 174.884 458.949 173.424C458.153 171.932 457.059 170.771 455.666 169.942C454.273 169.113 452.664 168.698 450.84 168.698C449.049 168.698 447.441 169.113 446.015 169.942C444.589 170.771 443.478 171.932 442.682 173.424C441.886 174.917 441.488 176.625 441.488 178.548V179.096C441.488 181.086 441.919 182.843 442.781 184.369C443.677 185.861 444.904 187.039 446.462 187.901C448.054 188.73 449.862 189.145 451.885 189.145C453.477 189.145 454.953 188.863 456.313 188.299C457.706 187.735 458.899 186.873 459.894 185.712L461.884 188.001C460.724 189.394 459.264 190.455 457.507 191.184C455.782 191.914 453.875 192.279 451.786 192.279Z" />
    <path d="M486.354 192.03V186.259L486.205 185.314V175.663C486.205 173.441 485.574 171.733 484.314 170.539C483.087 169.345 481.246 168.748 478.792 168.748C477.101 168.748 475.492 169.03 473.967 169.594C472.441 170.158 471.147 170.904 470.086 171.832L468.494 169.196C469.821 168.068 471.413 167.206 473.27 166.609C475.127 165.979 477.084 165.664 479.14 165.664C482.523 165.664 485.127 166.509 486.951 168.201C488.808 169.859 489.737 172.396 489.737 175.812V192.03H486.354ZM477.349 192.279C475.393 192.279 473.685 191.964 472.225 191.334C470.799 190.67 469.705 189.775 468.942 188.647C468.179 187.487 467.798 186.16 467.798 184.667C467.798 183.308 468.113 182.081 468.743 180.986C469.406 179.858 470.468 178.963 471.927 178.3C473.419 177.603 475.409 177.255 477.897 177.255H486.901V179.892H477.996C475.476 179.892 473.718 180.339 472.723 181.235C471.761 182.13 471.28 183.241 471.28 184.568C471.28 186.06 471.861 187.254 473.021 188.15C474.182 189.045 475.807 189.493 477.897 189.493C479.887 189.493 481.595 189.045 483.021 188.15C484.48 187.221 485.541 185.895 486.205 184.17L487.001 186.608C486.337 188.332 485.176 189.709 483.518 190.737C481.893 191.765 479.837 192.279 477.349 192.279Z" />
    <path d="M507.588 192.279C505.134 192.279 503.244 191.616 501.917 190.289C500.591 188.962 499.927 187.089 499.927 184.667V160.142H503.459V184.468C503.459 185.994 503.841 187.171 504.604 188.001C505.399 188.83 506.527 189.244 507.986 189.244C509.545 189.244 510.839 188.797 511.867 187.901L513.11 190.438C512.414 191.068 511.568 191.533 510.573 191.831C509.611 192.13 508.617 192.279 507.588 192.279ZM495.251 168.848V165.912H511.419V168.848H495.251Z" />
  </svg>
);