import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { fonts, colours } from '../../style/variables';

export const globalStyles = css`
  html,
  body {
    margin: 0;
  }
  body {
    font-family: ${fonts.body};
    font-size: 16px;
    color: ${colours.black};
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  a {
    color: ${colours.black};
    text-decoration: underline;
  }

  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${fonts.brand};
    font-weight: 400;
    line-height: 85%;
  }

  h1 {
    font-size: 74px;
  }
  h2 {
    font-size: 59px;
  }
  h3 {
    font-size: 47px;
  }
  h4 {
    font-size: 38px;
  }
  h5 {
    font-size: 30px;
  }
  h6 {
    font-size: 24px;
  }
`;

export const LayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const LayoutMain = styled.div`
  flex: 1;
`;
