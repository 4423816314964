import { css } from '@emotion/core';
import tinycolor from 'tinycolor2';

import { colours } from './variables';

export const accentLink = css`
  color: ${colours.black};
  transition: color 0.25s ease-in-out;

  &:hover,
  &:focus {
    color: ${colours.primary};
  }

  &:active {
    color: ${tinycolor(colours.primary).darken(10).toHex()};
  }
`;
