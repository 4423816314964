import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { colours } from '../../style/variables';

export const AuthorIdentityImage = styled(GatsbyImage)`
  width: 32px;
  height: 32px;
  flex: 0 0 auto;
  margin-right: 8px;
  border-radius: 50%;
`;

export const AuthorIdentityRoot = styled(Link)`
  display: flex;
  align-items: center;
  color: ${colours.black};
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
  overflow: hidden;

  ${AuthorIdentityImage} {
    transition: transform 0.2s ease-in-out;
  }

  &:focus,
  &:active,
  &:hover {
    color: ${colours.primary};

    ${AuthorIdentityImage} {
      transform: scale(0.95);
    }
  }
`;

export const AuthorIdentityName = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1 1 auto;
`;
