import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Global } from '@emotion/core';

import { LayoutRoot, LayoutMain, globalStyles } from './Layout.style';
import Footer from '../Footer';
import PlantyAdvert from '../PlantyAdvert';

const Layout: FunctionComponent = ({ children }) => (
  <LayoutRoot>
    <Helmet>
      {/* Fonts */}
      <link rel="stylesheet" href="/static/fonts/fonts.css" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;0,700;1,300&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <Global styles={globalStyles} />
    <LayoutMain>{children}</LayoutMain>
    <PlantyAdvert />
    <Footer />
  </LayoutRoot>
);

export default Layout;
