import { breakpoints } from './variables';
import { css } from '@emotion/core';

export interface ResponsiveValue<T> {
  mobile: T;
  desktop: T;
}

export type ScreenSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface Breakpoint {
  min?: Exclude<ScreenSize, 'xxs'> | number;
  max?: Exclude<ScreenSize, 'xl'> | number;
}

export const breakpoint = (query: Breakpoint | ScreenSize) => {
  const { min = 'xxs', max = 'xl' } =
    typeof query === 'string' ? { min: query, max: query } : query;
  const minPixels = typeof min === 'number' ? min : breakpoints[min].min;
  const maxPixels = typeof max === 'number' ? max : breakpoints[max].max;

  if (minPixels > 0 && maxPixels < Infinity) {
    return `(min-width: ${minPixels}px) and (max-width: ${maxPixels}px)`;
  }
  if (maxPixels === Infinity) {
    return `(min-width: ${minPixels}px)`;
  }
  if (minPixels === 0) {
    return `(max-width: ${maxPixels}px)`;
  }
  return '(min-width: 0px)';
};

export const cover = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
