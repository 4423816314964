import React, { FunctionComponent } from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import {
  ArticlePreviewLink,
  ArticlePreviewImage,
  ArticlePreviewTitleRow,
  ArticlePreviewTag,
  ArticlePreviewTags,
  ArticlePreviewDescription,
  ArticlePreviewFooter,
  ArticlePreviewDate,
  ArticlePreviewTitleText,
  ArticlePreviewRoot,
  PreviewAuthorIdentity,
} from './ArticlePreview.style';
import { ArticlePreviewFragment } from '../../../generated/graphql-types';

interface ArticlePreviewProps {
  article: ArticlePreviewFragment;
}

const ArticlePreview: FunctionComponent<ArticlePreviewProps> = ({
  article: { heroImage, slug, title, description, tags, author, publishDate },
}) => (
  <ArticlePreviewRoot>
    <ArticlePreviewLink to={`/post/${slug}`}>
      {heroImage?.gatsbyImageData && (
        <ArticlePreviewImage image={getImage(heroImage as any) as any} alt={title ?? 'Article'} />
      )}
      <ArticlePreviewTitleRow>
        <ArticlePreviewTitleText>{title}</ArticlePreviewTitleText>
        {tags && tags.length > 0 && (
          <ArticlePreviewTags>
            {tags.map((tag) => (
              <ArticlePreviewTag key={tag}>{tag}</ArticlePreviewTag>
            ))}
          </ArticlePreviewTags>
        )}
      </ArticlePreviewTitleRow>
    </ArticlePreviewLink>
    {description?.childMarkdownRemark?.html && (
      <ArticlePreviewDescription
        dangerouslySetInnerHTML={{
          __html: description?.childMarkdownRemark.html,
        }}
      />
    )}
    <ArticlePreviewFooter>
      {author && <PreviewAuthorIdentity author={author} />}
      <ArticlePreviewDate>{publishDate}</ArticlePreviewDate>
    </ArticlePreviewFooter>
  </ArticlePreviewRoot>
);

export default ArticlePreview;

export const articlePreviewFragment = graphql`
  fragment ArticlePreview on ContentfulArticle {
    title
    slug
    publishDate(formatString: "MMM Do YYYY")
    tags
    heroImage {
      gatsbyImageData(height: 450, placeholder: BLURRED)
    }
    description {
      childMarkdownRemark {
        html
      }
    }
    author {
      ...AuthorIdentity
    }
  }
`;
