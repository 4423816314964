import React, { FunctionComponent } from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import {
  AuthorIdentityRoot,
  AuthorIdentityImage,
  AuthorIdentityName,
} from './AuthorIdentity.style';
import { AuthorIdentityFragment } from '../../../generated/graphql-types';

interface AuthorIdentityProps {
  className?: string;
  author: AuthorIdentityFragment;
}

const AuthorIdentity: FunctionComponent<AuthorIdentityProps> = ({
  className,
  author,
}) => (
  <AuthorIdentityRoot to={`/author/${author.slug}`} className={className}>
    <AuthorIdentityImage image={getImage(author?.image as any) as any} alt={author?.name ?? 'Author'} />
    <AuthorIdentityName title={author.name ?? undefined}>{author.name}</AuthorIdentityName>
  </AuthorIdentityRoot>
);

export default AuthorIdentity;

export const articleAuthorFragment = graphql`
  fragment AuthorIdentity on ContentfulPerson {
    name
    slug
    image {
      gatsbyImageData(height: 160, placeholder: BLURRED)
    }
  }
`;
