import styled from '@emotion/styled';

import freezerHand from '../../assets/freezer-hand.jpg';
import { breakpoint } from '../../style/utils';
import { colours, fonts } from '../../style/variables';
import { ButtonOutboundLink } from '../Button';

const verticalBreakpoint = breakpoint({ max: 'sm' });

export const PlantyAdvertRoot = styled.div`
  display: flex;
  position: relative;
  height: 480px;
  background-color: ${colours.stone};

  @media ${verticalBreakpoint} {
    flex-direction: column;
    height: auto;
  }
`;

export const ImageSection = styled.a`
  position: relative;
  z-index: 1;
  display: block;
  flex: 1 1 50%;
  background-image: url("${freezerHand}");
  background-size: cover;
  background-position: left center;

  @media ${verticalBreakpoint} {
    flex: 0 0 320px;
  }
  @media ${breakpoint({ max: 'xs' })} {
    flex: 0 0 240px;
  }
`;

export const BlockedHeader = styled.h2`
  margin: 0;
  margin-top: 32px;
`;

export const BlockedLine = styled.span`
  font-family: ${fonts.brand};
  line-height: 80%;
  display: block;
  color: ${colours.primary};
`;

export const TextSection = styled.div`
  position: relative;
  z-index: 1;
  flex: 1 0 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > ${BlockedLine}:first-of-type {
    margin-bottom: 2px;
    margin-left: -280px;
  }
  > ${BlockedLine}:last-of-type {
    margin-left: -40px;
  }

  @media ${breakpoint('lg')} {
    > ${BlockedLine}:first-of-type {
      margin-left: -160px;
    }
    > ${BlockedLine}:last-of-type {
      margin-left: 40px;
    }
  }
  @media ${breakpoint('md')} {
    > ${BlockedLine}:first-of-type {
      margin-left: -120px;
    }
    > ${BlockedLine}:last-of-type {
      margin-left: 20px;
    }
  }
  @media ${verticalBreakpoint} {
    flex: 0 0 auto;
  }
  @media ${breakpoint('sm')} {
    padding: 60px 0;
  }
  @media ${breakpoint({ max: 'xs' })} {
    padding: 40px 0;

    > ${BlockedLine}:first-of-type {
      margin-left: -120px;
    }
    > ${BlockedLine}:last-of-type {
      margin-left: 32px;
    }
  }
`;

export const WebsiteLinkRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  margin-top: 32px;
  padding-left: 220px;

  @media ${breakpoint('lg')} {
    padding-left: 120px;
  }
  @media ${breakpoint('md')} {
    padding-left: 60px;
  }
  @media ${breakpoint({ max: 'xs' })} {
    padding-left: 32px;
  }
`;

export const WebsiteLinkRowArrow = styled.img`
  width: 68px;
  margin-right: 28px;
  position: relative;
  top: -12px;

  @media ${breakpoint({ max: 'xs' })} {
    width: 52px;
    margin-right: 20px;
    top: -12px;
  }
`;

export const WebsiteLinkButton = styled(ButtonOutboundLink)`
  transform: rotate(5deg);
  position: relative;
  top: 12px;
`;
