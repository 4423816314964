import React, { FunctionComponent } from 'react';

import {
  PlantyAdvertRoot,
  TextSection,
  ImageSection,
  BlockedLine,
  WebsiteLinkRow,
  WebsiteLinkRowArrow,
  WebsiteLinkButton,
  BlockedHeader
} from './PlantyAdvert.style';
import arrowDownRight from '../../assets/arrow-down-right.svg';
import { Logo } from '../Logo';
import { colours } from '../../style/variables';

const PlantyAdvert: FunctionComponent = () => (
  <PlantyAdvertRoot>
    <TextSection>
      <Logo kind="full" width={160} fill={colours.eggplant} />
      <BlockedHeader>
        <BlockedLine style={{ fontSize: 120 }}>
          Check out
        </BlockedLine>
        <BlockedLine style={{ fontSize: 80 }}>
          what's cooking
        </BlockedLine>
      </BlockedHeader>
      <WebsiteLinkRow>
        <WebsiteLinkRowArrow src={arrowDownRight} alt="Arrow" />
        <WebsiteLinkButton
          size="large"
          kind="primary"
          href="https://planty.uk#menu"
        >
          See our menu
        </WebsiteLinkButton>
      </WebsiteLinkRow>
    </TextSection>
    <ImageSection />
  </PlantyAdvertRoot>
);

export default PlantyAdvert;
