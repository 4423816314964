import React, { FunctionComponent, Fragment } from 'react';
import { Helmet } from 'react-helmet';

interface OpenGraphImage {
  url: string;
  secure_url?: string;
  type?: string;
  width?: number;
  height?: number;
  alt: string;
}

type ISODate = string;

interface OpenGraphArticle {
  published_time?: ISODate;
  modified_time?: ISODate;
  author?: string;
  section?: string;
  tags?: string[];
}

type OpenGraphType = 'website' | 'article';

export interface SEOTagsProps {
  title?: string;
  description?: string;
  canonical: string;
  openGraph: {
    title: string;
    description?: string;
    type: OpenGraphType;
    url: string;
    images: OpenGraphImage[];
    determiner?: string;
    locale?: string;
    localeAlternate?: string;
    siteName?: string;
    article?: OpenGraphArticle;
  };
}

interface OpenGraphTag {
  property: string;
  content?: string;
}

const SEOTags: FunctionComponent<SEOTagsProps> = ({
  title,
  description,
  canonical,
  openGraph,
}) => {
  const openGraphTags: OpenGraphTag[] = [
    {
      property: 'og:title',
      content: openGraph.title,
    },
    {
      property: 'og:description',
      content: openGraph.description,
    },
    {
      property: 'og:type',
      content: openGraph.type,
    },
    {
      property: 'og:url',
      content: openGraph.url,
    },
  ];

  openGraph.images.forEach((image) => {
    openGraphTags.push(
      ...[
        {
          property: 'og:image',
          content: image.url,
        },
        {
          property: 'og:image:secure_url',
          content: image.secure_url,
        },
        {
          property: 'og:image:type',
          content: image.type,
        },
        {
          property: 'og:image:width',
          content: image.width?.toString(),
        },
        {
          property: 'og:image:height',
          content: image.height?.toString(),
        },
        {
          property: 'og:image:alt',
          content: image.alt,
        },
      ]
    );
  });

  if (openGraph.article) {
    openGraphTags.push(
      ...[
        {
          property: 'article:published_time',
          content: openGraph.article.published_time,
        },
        {
          property: 'article:author',
          content: openGraph.article.author,
        },
      ]
    );
  }

  const filteredOpenGraphTags = openGraphTags.filter(
    (tag) => tag.content
  ) as Required<OpenGraphTag>[];

  return (
    <Helmet>
      {/* Regular SEO tags */}
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {canonical && <link rel="canonical" href={canonical} />}
      {/* Open Graph tags */}
      {filteredOpenGraphTags.map((tag) => (
        <meta
          key={tag.property}
          property={tag.property}
          content={tag.content}
        />
      ))}
    </Helmet>
  );
};

export default SEOTags;
