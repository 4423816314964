import styled from '@emotion/styled';
import { breakpoint } from '../../style/utils';

const gutter = {
  desktop: 48,
  mobile: 28,
};

export const ArticlePreviewListRoot = styled.ul`
  list-style: none;
  margin: -${gutter.desktop / 2}px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  @media ${breakpoint('md')} {
    margin: -${gutter.mobile / 2}px;
  }
`;

export const ArticlePreviewListItem = styled.li`
  padding: ${gutter.desktop / 2}px;
  flex: 0 0 50%;

  @media ${breakpoint('md')} {
    padding: ${gutter.mobile / 2}px;
  }
  @media ${breakpoint({ max: 'sm' })} {
    flex: 0 0 100%;
  }
`;
