import React, { FunctionComponent } from 'react';

import { ArticlePreviewFragment } from '../../../generated/graphql-types';
import {
  ArticlePreviewListRoot,
  ArticlePreviewListItem,
} from './ArticlePreviewList.style';
import ArticlePreview from '../ArticlePreview';

interface ArticlePreviewListProps {
  articles: ArticlePreviewFragment[];
}

const ArticlePreviewList: FunctionComponent<ArticlePreviewListProps> = ({
  articles,
}) => {
  if (articles.length === 0) {
    return null;
  }
  return (
    <ArticlePreviewListRoot>
      {articles.map((article) => (
        <ArticlePreviewListItem key={article.slug}>
          <ArticlePreview article={article} />
        </ArticlePreviewListItem>
      ))}
    </ArticlePreviewListRoot>
  );
};

export default ArticlePreviewList;
