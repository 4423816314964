import { SerializedStyles, css } from '@emotion/core';
import styled, { StyledComponent } from '@emotion/styled';
import tinycolor from 'tinycolor2';
import isPropValid from '@emotion/is-prop-valid';

import { colours, fonts } from '../../style/variables';
import {
  OutboundLink,
  OutboundLinkProps,
} from 'gatsby-plugin-google-analytics';

export interface ButtonOptions {
  size?: ButtonSize;
  kind?: ButtonKind;
  block?: boolean;
}

export type ButtonSize = 'small' | 'base' | 'large' | 'super';
export type ButtonKind = 'primary';

interface ButtonSizeVariantParams {
  fontSize: number;
  xPadding: number;
  height: number;
}

const createButtonSizeVariant = ({
  fontSize,
  xPadding,
  height,
}: ButtonSizeVariantParams) => css`
  font-size: ${fontSize}px;
  padding: 0 ${xPadding}px;
  height: ${height}px;
  line-height: ${height}px;
`;

const stylesBySize: Record<ButtonSize, SerializedStyles> = {
  small: createButtonSizeVariant({
    fontSize: 18,
    xPadding: 12,
    height: 32,
  }),
  base: createButtonSizeVariant({
    fontSize: 24,
    xPadding: 20,
    height: 40,
  }),
  large: createButtonSizeVariant({
    fontSize: 28,
    xPadding: 24,
    height: 56,
  }),
  super: createButtonSizeVariant({
    fontSize: 36,
    xPadding: 24,
    height: 68,
  }),
};

interface ButtonKindVariantParams {
  color: string;
  background: string;
  onInteraction: 'lighten' | 'darken';
}

const createButtonKindVariant = ({
  color,
  background,
  onInteraction,
}: ButtonKindVariantParams) => css`
  background-color: ${background};
  color: ${color};

  &:hover,
  &:focus {
    background-color: ${tinycolor(background)
      .lighten(onInteraction === 'lighten' ? 8 : -8)
      .toHexString()};
    color: ${tinycolor(color)
      .lighten(onInteraction === 'lighten' ? 8 : -8)
      .toHexString()};
  }
  &:active {
    background-color: ${tinycolor(background)
      .lighten(onInteraction === 'lighten' ? 16 : -16)
      .toHexString()};
    color: ${tinycolor(color)
      .lighten(onInteraction === 'lighten' ? 16 : -16)
      .toHexString()};
  }
`;

const stylesByKind: Record<ButtonKind, SerializedStyles> = {
  primary: createButtonKindVariant({
    color: colours.white,
    background: colours.primary,
    onInteraction: 'lighten',
  }),
};

export const buttonStyles = ({
  block,
  size,
  kind,
}: Required<ButtonOptions>) => css`
  outline: none;
  font-weight: 400;
  display: ${block ? 'block' : 'inline-block'};
  text-transform: uppercase;
  letter-spacing: 0.04em;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  font-family: ${fonts.brand};

  ${stylesBySize[size]};
  ${stylesByKind[kind]};
`;

export const StyledButton = styled.button<Required<ButtonOptions>>`
  ${buttonStyles};
`;

export const StyledButtonLink = styled.a<Required<ButtonOptions>>`
  ${buttonStyles};
  text-decoration: none;
`;

export const StyledButtonOutboundLink = styled(OutboundLink as any, {
  shouldForwardProp: isPropValid,
})`
  ${buttonStyles};
  text-decoration: none;
` as StyledComponent<OutboundLinkProps, Required<ButtonOptions>, object>;
