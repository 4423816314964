import React, { Fragment, FunctionComponent } from 'react';
import { Link } from 'gatsby';

import {
  NavbarRoot,
  NavbarLogo,
  NavbarLinks,
  ExternalNavbarLink,
  NavbarRight,
} from './Navbar.style';

interface NavbarProps {
  overlay?: boolean;
  showLogo?: boolean;
}

const plantyWebsite = 'https://planty.uk';

const Navbar: FunctionComponent<NavbarProps> = ({
  overlay = false,
  showLogo = true,
}) => (
  <NavbarRoot overlay={overlay}>
    <NavbarLinks>
      <ExternalNavbarLink
        href={`${plantyWebsite}#menu`}
        style={{ fontWeight: 900 }}
      >
        Our Menu
      </ExternalNavbarLink>
      <ExternalNavbarLink href={`${plantyWebsite}/about`}>
        About us
      </ExternalNavbarLink>
    </NavbarLinks>
    {showLogo && (
      <Fragment>
        <Link to="/">
          <NavbarLogo kind="wordmark" height={36} title="Planty Logo" />
        </Link>
        <NavbarRight />
      </Fragment>
    )}
  </NavbarRoot>
);

export default Navbar;
