import styled from '@emotion/styled';

export const colours = {
  black: '#222222',
  shadow: '#363640',
  white: '#FFFFFF',
  primary: '#66348B',
  eggplant: '#66348B',
  stone: '#F9F4F0',
  grey: {
    lightest: '#F4F4F4',
    lighter: '#EEEEEE',
    light: '#CCCCCC',
  },
};

export const fonts = {
  brand: `Bison, Impact, Tahoma, sans-serif`,
  body: 'Montserrat, sans-serif',
};

export const minBreakpointWidths = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const spacing = {
  pagePadding: 20,
  navbarHeight: 72,
};

export const breakpoints = {
  xxs: {
    min: 0,
    max: minBreakpointWidths.xs - 1,
  },
  xs: {
    min: minBreakpointWidths.xs,
    max: minBreakpointWidths.sm - 1,
  },
  sm: {
    min: minBreakpointWidths.sm,
    max: minBreakpointWidths.md - 1,
  },
  md: {
    min: minBreakpointWidths.md,
    max: minBreakpointWidths.lg - 1,
  },
  lg: {
    min: minBreakpointWidths.lg,
    max: minBreakpointWidths.xl - 1,
  },
  xl: {
    min: minBreakpointWidths.xl,
    max: Infinity,
  },
};

export const shadows = {
  header: '0 8px 50px 0 rgba(0, 0, 0, 0.12)',
  popup: '0 4px 8px 0 rgba(0, 0, 0, 0.10)',
};
