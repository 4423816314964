import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Link } from 'gatsby';
import { breakpoint } from '../../style/utils';
import { spacing, colours } from '../../style/variables';
import AuthorIdentity from '../AuthorIdentity';

export const ArticlePreviewImage = styled(GatsbyImage)`
  height: 150px;
  border-radius: 12px;
  margin: 0 0 16px 0;

  @media ${breakpoint('md')} {
    height: 120px;
  }
  @media ${breakpoint('xxs')} {
    max-width: none;
    border-radius: 0;
    margin: 0 -${spacing.pagePadding}px 16px -${spacing.pagePadding}px;
  }
`;

export const ArticlePreviewTitleRow = styled.h4`
  margin: 0 0 12px 0;
  line-height: 100%;
`;

export const ArticlePreviewTitleText = styled.span`
  margin-right: 10px;
  line-height: 32px;
`;

export const ArticlePreviewTags = styled.span`
  margin: 0 -3px;
  display: inline-flex;
  height: 38px;
  vertical-align: top;
  align-items: center;
`;

export const ArticlePreviewTag = styled.span`
  display: block;
  margin: 3px;
  background-color: #b5dbf6;
  font-weight: bold;
  font-size: 18px;
  border-radius: 28px;
  height: 28px;
  line-height: 28px;
  padding: 0 10px;
  font-style: normal;
`;

export const ArticlePreviewDescription = styled.div`
  margin: 0 0 12px 0;

  > p:first-of-type {
    margin-top: 0;
  }
  > p:last-of-type {
    margin-bottom: 0;
  }
`;

export const ArticlePreviewFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`;

export const PreviewAuthorIdentity = styled(AuthorIdentity)`
  padding-right: 20px;
`;

export const ArticlePreviewDate = styled.span`
  opacity: 0.6;
  font-style: italic;
`;

export const ArticlePreviewRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ArticlePreviewLink = styled(Link)`
  display: flex;
  flex-direction: column;
  color: inherit;
  text-decoration: none;

  ${ArticlePreviewImage} {
    transition: transform 0.2s ease-in-out;
  }
  ${ArticlePreviewTitleText} {
    transition: color 0.2s ease-in-out;
  }

  &:focus,
  &:hover,
  &:active {
    ${ArticlePreviewImage} {
      transform: scale(0.99);
    }
    ${ArticlePreviewTitleText} {
      color: ${colours.primary};
    }
  }
`;
