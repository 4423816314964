import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { accentLink } from '../../style/link';
import { breakpoint } from '../../style/utils';
import { spacing } from '../../style/variables';
import { Logo } from '../Logo';

interface NavbarStyleProps {
  overlay: boolean;
}

const absoluteStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

export const NavbarRoot = styled.nav<NavbarStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  background-color: ${({ overlay }) => (overlay ? 'transparent' : 'white')};
  ${({ overlay }) => overlay && absoluteStyles};
  z-index: 1;
  padding: 0 32px;

  @media ${breakpoint({ max: 'xs' })} {
    height: ${spacing.navbarHeight}px;
  }
`;

export const NavbarLinks = styled.ul`
  flex: 1 0 0;
  margin: 0;
  padding: 0;
`;

interface ExternalNavbarLinkProps {
  hideOnMobile?: boolean;
}

export const ExternalNavbarLink = styled.a<ExternalNavbarLinkProps>`
  ${accentLink};
  font-size: 18px;
  text-decoration: none;

  ${({ hideOnMobile }) =>
    hideOnMobile &&
    css`
      @media ${breakpoint('xxs')} {
        display: none;
      }
    `}

  &:not(:last-of-type) {
    margin-right: 16px;
  }
`;

export const NavbarLogo = styled(Logo)`
  transition: transform 0.2s ease-in-out;
  display: block;
  flex: 0 0 auto;

  &:hover {
    transform: scale(0.95);
  }

  @media ${breakpoint({ max: 'xs' })} {
    height: 40px;
  }
  @media ${breakpoint({ max: 'sm' })} {
    display: none;
  }
`;

export const NavbarRight = styled.div`
  flex: 1 0 0;
  @media ${breakpoint({ max: 'sm' })} {
    display: none;
  }
`;
