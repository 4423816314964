import React, { FunctionComponent } from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import {
  FooterRoot,
  Copyright,
  SocialLinks,
  SocialLink,
  SocialLinkImage,
} from './Footer.style';
import facebookIcon from '../../assets/social/facebook-icon.svg';
import instagramIcon from '../../assets/social/instagram-icon.svg';
import { Logo } from '../Logo';

const Footer: FunctionComponent = () => (
  <FooterRoot>
    <OutboundLink href="https://planty.uk">
      <Logo kind="full" width={120} title="Planty logo" />
    </OutboundLink>
    <Copyright>Copyright {new Date().getFullYear()} Planty Ltd</Copyright>
    <SocialLinks>
      <SocialLink href="https://www.instagram.com/planty.uk/">
        <SocialLinkImage src={instagramIcon} alt="Instagram logo" />
      </SocialLink>
      <SocialLink href="https://www.facebook.com/planty.uk">
        <SocialLinkImage src={facebookIcon} alt="Facebook logo" />
      </SocialLink>
    </SocialLinks>
  </FooterRoot>
);

export default Footer;
