import styled from '@emotion/styled';

import { colours } from '../../style/variables';
import { Logo } from '../Logo';

export const FooterRoot = styled.footer`
  background-color: ${colours.black};
  color: ${colours.white};
  padding: 60px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FooterLogo = styled(Logo)`
  margin-bottom: 32px;
  transition: transform 0.2s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    transform: scale(0.95);
  }
`;

export const Copyright = styled.p`
  margin-top: 0;
  margin-bottom: 32px;
  opacity: 0.8;
`;

export const SocialLinks = styled.div`
  display: flex;
  align-items: center;
`;

export const SocialLink = styled.a`
  padding: 0 8px;
`;

export const SocialLinkImage = styled.img`
  height: 36px;
`;
