import React, { FunctionComponent, HTMLAttributes } from 'react';
import styled from '@emotion/styled';

import { colours } from '../../style/variables';

interface DividerProps {
  height?: number;
}

const DividerElement = styled.hr<Required<DividerProps>>`
  border: none;
  width: 100%;
  margin: 40px 0;
  height: ${({ height }) => height}px;
  border-radius: ${({ height }) => height}px;
  background-color: ${colours.grey.lighter};
`;

const Divider: FunctionComponent<
  DividerProps & HTMLAttributes<HTMLHRElement>
> = ({ height = 3, ...props }) => <DividerElement height={height} {...props} />;

export default Divider;
