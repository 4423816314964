import React, {
  FunctionComponent,
  HTMLAttributes,
  AnchorHTMLAttributes,
} from 'react';
import {
  ButtonOptions,
  StyledButton,
  StyledButtonLink,
  StyledButtonOutboundLink,
} from './Button.style';
import { OutboundLinkProps } from 'gatsby-plugin-google-analytics';

type ButtonProps = HTMLAttributes<HTMLButtonElement> & ButtonOptions;
type ButtonLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & ButtonOptions;
type ButtonOutboundLinkProps = OutboundLinkProps & AnchorHTMLAttributes<HTMLAnchorElement> & ButtonOptions;

const Button: FunctionComponent<ButtonProps> = ({
  children,
  size = 'base',
  kind = 'primary',
  block = false,
  ...rest
}) => (
  <StyledButton size={size} kind={kind} block={block} {...rest}>
    {children}
  </StyledButton>
);

export const ButtonLink: FunctionComponent<ButtonLinkProps> = ({
  children,
  size = 'base',
  kind = 'primary',
  block = false,
  ...rest
}) => (
  <StyledButtonLink size={size} kind={kind} block={block} {...rest}>
    {children}
  </StyledButtonLink>
);

export const ButtonOutboundLink: FunctionComponent<ButtonOutboundLinkProps> = ({
  children,
  size = 'base',
  kind = 'primary',
  block = false,
  ...rest
}) => (
  <StyledButtonOutboundLink size={size} kind={kind} block={block} {...rest}>
    {children}
  </StyledButtonOutboundLink>
);

export default Button;
